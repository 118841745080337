import styled from "styled-components";
import { motion } from "framer-motion";

const OuterWrapper = styled.div`
  position: relative;
  border-radius: 10px;
  border-radius: 10px;
  height: 325px;
  width: 600px;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    width: 95%;
    height: auto;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  border-radius: 10px;
  background-color: #fff;
  /* border: 2px solid #002c3e; */
  box-shadow: 0 0 5px 5px #ededed;
  color: #002c3e;
  border-radius: 10px;
  overflow: hidden;
  flex-direction: row;
  height: 325px;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    padding: 0 0.5em 0.5em 0.5em;
    max-height: none;
    flex-direction: column;
    width: 100%;
    height: max-content;
  }

  * > img {
    height: 140px;
    width: 140px;
  }
`;

const ContentRow = styled.div`
  width: 100%;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: row;
  }
`;

const ContentColumn = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 800px) {
    flex-direction: row;
    height: auto;
  }

  ${({ centered }) =>
    centered &&
    `
    justify-content: center;
  `}
`;

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0px 30px;
  justify-content: space-between;

  ${({ right }) =>
    right &&
    `
  justify-content: right;
  `}

  @media (max-width: 800px) {
    padding: 0.5em;
    flex-wrap: wrap;
  }
`;

const Perk = styled.div`
  margin: 0 1em 1em 1em;
  height: 40%;
  flex-basis: 100%;

  @media (max-width: 800px) {
    margin: 0.2em;
  }
`;

const PerksHeader = styled.div`
  font-size: 1.4em;
  color: #002c3e;
  margin-top: 2em;
  border-left: 1px solid #f7444e;
  padding-left: 0.5em;
`;

const PerksContent = styled.div`
  padding-top: 1.5em;
  height: 100%;
  font-size: 1.1em;
`;

const HighlightColor = styled.p`
  color: #f7444e;
  display: inline;
`;

const AlignedCenter = styled.div`
  padding-top: 1em;
`;

const LinkAlignedCenter = styled.div`
  display: flex;
  width: 100%;
  justify-content: right;
`;

const StyledLink = styled.a`
  /* display: flex;
  justify-content: center;
  text-decoration: none; */
  @media (max-width: 800px) {
    align-items: center;
  }
`;

const BonusButton = styled(motion.button)`
  padding: 1em;
  color: #fff;
  background-color: #78bcc4;
  border: 0;
  border-radius: 5px;
  padding: 2em;
  height: 2em;
  cursor: pointer;
  line-height: 5px;
  font-size: 1.1em;
  margin-top: 20px;
  align-self: flex-end;
  letter-spacing: 3px;
  text-align: center;

  @media (max-width: 800px) {
    line-height: inherit;
    padding: 1.3em;
    height: auto;
  }
`;

const FeaturedTitle = styled.h2`
  color: #002c3e;
  position: absolute;
  margin-top: 5px;
  margin-left: 0.5em;
  text-decoration: underline;
`;

const StyledImage = styled.img`
  object-fit: cover;
  width: 140px;
  height: 140px;
  border-radius: 5px;
  margin-left: 10px;
  @media (max-width: 800px) {
    border-radius: 0 0 5px 5px;
  }
`;

const ChipWrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: -22px;
  left: -10px;
  display: flex;
  gap: 5px;
  font-size: 0.9em;
`;

const Chip = styled.div`
  border-radius: 10px;
  background-color: #f7444e;
  color: #fff;
  padding: 0.3em;
`;

const InvertedChip = styled.div`
  border-radius: 10px;
  color: #002c3e;
  background-color: #fff;
  padding: 0.3em;
`;

const BorderChip = styled.div`
  border-radius: 10px;
  color: #002c3e;
  background-color: #fff;
  padding: 0.3em;
  border-color: #f7444e;
  border: 1px solid red;
`;

const handleBonusClick = (bonusName) => {
  return window.analytics.goal("sfnh0vipabvb0u4y", bonusName);
};

const LinkedButton = ({ link, Button, bonusName }) => {
  return (
    <StyledLink
      href={link}
      target="_blank"
      onClick={() => handleBonusClick(bonusName)}
    >
      <Button />
    </StyledLink>
  );
};

const BareButton = () => {
  return (
    <BonusButton
      whileHover={{ scale: 1.1, boxShadow: "0 10px 20px #78bcc4" }}
      whileTap={{ scale: 0.9 }}
    >
      CLAIM
    </BonusButton>
  );
};

const LinkButton = () => {
  return (
    <BonusButton
      whileHover={{ scale: 1.1, boxShadow: "0 10px 20px #78bcc4" }}
      whileTap={{ scale: 0.9 }}
    >
      TO SITE
    </BonusButton>
  );
};

const LinkBonus = ({ index, bonus }) => {
  return (
    <OuterWrapper>
      <ChipWrapper>
        {bonus.license ? <Chip>{bonus.license}</Chip> : null}
        {bonus.payNplay ? <InvertedChip>Pay'N'Play</InvertedChip> : null}
        {bonus.nonSticky ? <BorderChip>Non-sticky</BorderChip> : null}
      </ChipWrapper>
      <Wrapper key={index}>
        <StyledImage src={bonus.img} width="140" alt="casino logo" />
        <TextWrapper right={"true"}>
          <Perk>
            <PerksContent>{bonus.text ? bonus.text : null}</PerksContent>
          </Perk>
          <Perk>
            <LinkAlignedCenter>
              <LinkedButton
                link={bonus.link}
                Button={LinkButton}
                bonusName={bonus.name}
              />
            </LinkAlignedCenter>
          </Perk>
        </TextWrapper>
      </Wrapper>
    </OuterWrapper>
  );
};

const NormalBonus = ({ index, bonus, featured }) => {
  return (
    <OuterWrapper>
      <ChipWrapper>
        {bonus.license ? <Chip>{bonus.license}</Chip> : null}
        {bonus.payNplay ? <InvertedChip>Pay'N'Play</InvertedChip> : null}
        {bonus.nonSticky ? <BorderChip>Non-sticky</BorderChip> : null}
      </ChipWrapper>
      <Wrapper key={index}>
        {featured ? <FeaturedTitle>EXCLUSIVE</FeaturedTitle> : null}
        <ContentColumn centered>
          <StyledImage
            src={bonus.img}
            width="140"
            height="140"
            alt="casino logo"
          />
        </ContentColumn>
        <ContentColumn>
          <TextWrapper>
            <ContentRow>
              <Perk>
                <PerksHeader>Free spins</PerksHeader>
                <PerksContent>
                  {bonus.perks.freeSpins ? (
                    <>
                      {bonus.perks.freeSpins.value}
                      <HighlightColor>x</HighlightColor>
                      <br />
                      {bonus.perks.freeSpins?.condition
                        ? `(${bonus.perks.freeSpins.condition})`
                        : null}
                    </>
                  ) : (
                    "-"
                  )}
                </PerksContent>
              </Perk>
              <Perk>
                <PerksHeader>Bonus</PerksHeader>
                <PerksContent>
                  {bonus.perks.bonus ? (
                    <>
                      {bonus.perks.bonus.percentage}%
                      <br />
                      up to {bonus.perks.bonus.maxSum} €
                      <br />
                      {bonus.perks.bonus?.condition
                        ? `(${bonus.perks.bonus.condition})`
                        : null}
                    </>
                  ) : (
                    "-"
                  )}
                </PerksContent>
              </Perk>
            </ContentRow>
            <ContentRow>
              <Perk>
                <PerksHeader>Wager</PerksHeader>
                <PerksContent>
                  {bonus.perks.wager ? (
                    <>
                      {bonus.perks.wager.value}
                      <HighlightColor>x</HighlightColor>
                      <br />
                      {bonus.perks.wager?.condition
                        ? `(${bonus.perks.wager.condition})`
                        : null}
                    </>
                  ) : (
                    "-"
                  )}
                </PerksContent>
              </Perk>
              <Perk>
                <LinkedButton
                  link={bonus.link}
                  Button={BareButton}
                  bonusName={bonus.name}
                />
                <AlignedCenter>
                  {/* <License>
              Licence:
              <br />
              <HighlightColor>{bonus.license}</HighlightColor>
            </License> */}
                </AlignedCenter>
              </Perk>
            </ContentRow>
          </TextWrapper>
        </ContentColumn>
      </Wrapper>
    </OuterWrapper>
  );
};

const BonusBox = ({ index, bonus, featured }) => {
  if (bonus.type === "bonus") {
    return <NormalBonus index={index} bonus={bonus} featured={featured} />;
  }

  if (bonus.type === "link") {
    return <LinkBonus index={index} bonus={bonus} />;
  }
};

export default BonusBox;
